import React, { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Topbar from "./Topbar";

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState("userdashboard");
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));
  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const openPop = () => {
    $(".maindiv").toggleClass("main");
  };

  return (
    <>
      <div className="sidbar_left">
        <i className="fas fa-times side_b close" onClick={openPop}></i>
        <ul>
          <li className={activeItem === "userdashboard" ? "active" : ""}>
            <Link
              to="/userdashboard"
              className={activeItem === "userdashboard" ? "active" : ""}
              onClick={() => handleItemClick("userdashboard")}
            >
              <span>
                <img src="../images/iconS1.png" align="icon" />
              </span>{" "}
              News Feed
            </Link>
          </li>
          <li className={activeItem === "userprofile" ? "active" : ""}>
            <Link
              to="/userprofile"
              className={activeItem === "userprofile" ? "active" : ""}
              onClick={() => handleItemClick("userprofile")}
            >
              <span>
                <img src="../images/useri_1.png" align="icon" />
              </span>{" "}
              My Profile
            </Link>
          </li>
          <li className={activeItem === "messages" ? "active" : ""}>
            <Link
              to="/messages"
              className={activeItem === "messages" ? "active" : ""}
              onClick={() => handleItemClick("messages")}
            >
              <span>
                <img src="../images/iconS2.png" align="icon" />
              </span>{" "}
              Messages
            </Link>
          </li>
          <li className={activeItem === "requests" ? "active" : ""}>
            <Link
              to="/requests"
              className={activeItem === "requests" ? "active" : ""}
              onClick={() => handleItemClick("requests")}
            >
              <span>
                <img src="../images/iconS3.png" align="icon" />
              </span>{" "}
              Requests
            </Link>
          </li>
          {curentlogin.userroles==='3'&&<li className={activeItem === "Chat" ? "active" : ""}>
            <Link
              to="/chatrequests"
              className={activeItem === "Chat" ? "active" : ""}
              onClick={() => handleItemClick("Chat")}
            >
              <span>
                <img src="../images/iconS3.png" align="icon" />
              </span>{" "}
            Chat  Requests
            </Link>
          </li>}
          <li className={activeItem === "followers" ? "active" : ""}>
            <Link
              to="/followers"
              className={activeItem === "followers" ? "active" : ""}
              onClick={() => handleItemClick("followers")}
            >
              <span>
                <img src="../images/iconS4.png" align="icon" />
              </span>{" "}
              My Followers
            </Link>
          </li>
          <li className={activeItem === "blocklist" ? "active" : ""}>
            <Link
              to="/blocklist"
              className={activeItem === "blocklist" ? "active" : ""}
              onClick={() => handleItemClick("blocklist")}
            >
              <span>
                <img src="../images/iconS5.png" align="icon" />
              </span>{" "}
              Blocklist
            </Link>
          </li>
          <li className={activeItem === "viewnotifications" ? "active" : ""}>
            <Link
              to="/viewnotifications"
              className={activeItem === "viewnotifications" ? "active" : ""}
              onClick={() => handleItemClick("viewnotifications")}
            >
              <span>
                <i className="fas fa-bell" style={{ color: "#ffdc5d" }}>
                  <sup style={{ color: "#ff0000d6" }}></sup>
                </i>
              </span>{" "}
              Notifications
            </Link>
          </li>
          <li className={activeItem === "favorites" ? "active" : ""}>
            <Link
              to="/favorites"
              className={activeItem === "favorites" ? "active" : ""}
              onClick={() => handleItemClick("favorites")}
            >
              <span>
                <img src="../images/iconS8.png" align="icon" />
              </span>{" "}
              Favorites
            </Link>
          </li>
          <li className={activeItem === "terms" ? "active" : ""}>
            <Link
              to="/terms"
              className={activeItem === "terms" ? "active" : ""}
              onClick={() => handleItemClick("terms")}
            >
              <span>
                <img src="https://cdn-icons-png.flaticon.com/512/2234/2234821.png" align="icon" />
              </span>{" "}
              T&C
            </Link>
          </li>
          <li className={activeItem === "privacy" ? "active" : ""}>
            <Link
              to="/privacy"
              className={activeItem === "privacy" ? "active" : ""}
              onClick={() => handleItemClick("privacy")}
            >
              <span>
                <img src="https://png.pngtree.com/png-vector/20221007/ourmid/pngtree-privacy-policy-png-image_6290271.png" align="icon" />
              </span>{" "}
              Privacy Policy
            </Link>
          </li>
          <li className={activeItem === "support" ? "active" : ""}>
            <Link
              to="/support"
              className={activeItem === "support" ? "active" : ""}
              onClick={() => handleItemClick("support")}
            >
              <span>
                <img src="https://static.vecteezy.com/system/resources/thumbnails/009/665/096/small/live-support-icon-character-illustration-3d-image-isolated-transparent-background-png.png" align="icon" />
              </span>{" "}
              Support
            </Link>
          </li>
        </ul>
      </div>
      <Topbar />
    </>
  );
};

export default Sidebar;
